import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import useConfig from '../hooks/useConfig'
import useGetParams from '../hooks/useGetParams'
import { TypeSequence } from '../types'
import { useAdmin } from './hooks'
import Image from './Image'

type TypeNavigationProps = {}

const HOME_BUTTON = true

const DEFAULT_NAV_ITEM_COLOR = '#FFFFFF'
const DEFAULT_NAV_ITEM_EMPHASIS_COLOR = '#FFFFFF'
const DEFAULT_NAV_ITEM_TEXT_COLOR = '#000000'
const DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR = '#000000'

const Navigation = React.memo(function Navigation(props: TypeNavigationProps) {
    const { display_navigation_menu = true, sequence_array: global_sequence_array, color_array = [], display_logo } = useConfig()
    const { sequence_group_id, sequence_id } = useGetParams()
    const sequence_array = sequence_group_id ? global_sequence_array.filter((l) => l.sequence_group_id === sequence_group_id) : global_sequence_array
    const nav_item_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    const nav_item_emphasis_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis'), [])
    const nav_item_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_text'), [])
    const nav_item_emphasis_text_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis_text'), [])
    const nav_item_style = {
        backgroundColor: nav_item_color?.color ?? DEFAULT_NAV_ITEM_COLOR,
        color: nav_item_text_color?.color ?? DEFAULT_NAV_ITEM_TEXT_COLOR,
    }
    const nav_item_emphasis_style = {
        backgroundColor: nav_item_emphasis_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_COLOR,
        color: nav_item_emphasis_text_color?.color ?? DEFAULT_NAV_ITEM_EMPHASIS_TEXT_COLOR,
    }

    const { enabled } = useAdmin()

    return (
        <nav id="sequence-nav" className=" absolute top-0 left-0">
            <ul className={'pl-4 ' + (enabled ? 'pt-20' : 'pt-8')}>
                {(display_logo && (
                    <li className={'fixed top-0 right-0 p-3 ' + (enabled ? 'mt-16' : 'mt-12')}>
                        <Link to={`/`}>
                            <Image src={`logo.png`} />
                        </Link>
                    </li>
                )) ||
                    (display_navigation_menu && HOME_BUTTON && (
                        <li>
                            <Link to={`/`}>Accueil</Link>
                        </li>
                    )) ||
                    null}
                {(display_navigation_menu &&
                    sequence_array.length > 1 &&
                    sequence_array.map((sequence) => (
                        <NavItem
                            key={sequence.id}
                            sequence={sequence}
                            sequence_id={sequence_id}
                            sequence_group_id={sequence_group_id}
                            nav_item_style={nav_item_style}
                            nav_item_emphasis_style={nav_item_emphasis_style}
                        />
                    ))) ||
                    null}
            </ul>
        </nav>
    )
})

const NavItem = React.memo(function NavItem(props: any) {
    const { sequence, sequence_id, sequence_group_id, nav_item_style, nav_item_emphasis_style } = props
    const [hovered, setHovered] = useState(false)
    const em = sequence.id === sequence_id || hovered
    return (
        <li key={sequence.id}>
            <Link
                to={`/${sequence_group_id}/${sequence.id}`}
                className="p-3 block text-center"
                style={em ? nav_item_emphasis_style : nav_item_style}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
            >
                {sequence.label}
            </Link>
        </li>
    )
})

export default Navigation
