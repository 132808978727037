import React, { useMemo, useState } from 'react'
import { FILE_URL } from '../constants'
import { useAdmin, useConfig } from './hooks'
import Image from './Image'

const DetailsPane = React.memo(function DetailsPane(props: any) {
    const { position, isOpen, setIsOpen, click_zone, tooltipPosition } = props
    const config = useConfig()
    const { enabled } = useAdmin()

    const image = (click_zone?.image_id && config.image_array.find((i) => i.id === click_zone.image_id)) || null

    let positionClassName
    switch (position) {
        case 'tooltip':
            positionClassName = 'details-pane-tooltip'
            break
        default:
            positionClassName = 'details-pane-left'
            break
    }

    // const style = { tooltipPosition }
    const { top, left, width, height } = tooltipPosition || {}
    const style: any = { position: 'absolute', top: top + height / 2 + 'px', left: left + width / 2 + 'px' }
    return (
        <div
            className={`details-pane z-20 ${positionClassName} ${isOpen ? '' : 'hidden'} ${
                enabled && config.details_pane_position !== 'tooltip' ? 'pt-12' : ''
            }`}
            style={style}
        >
            <div className="relative px-3 pt-3">
                <div onClick={() => setIsOpen(false)} className="absolute cursor-pointer top-0 right-0 pr-3 mr-3 mt-5">
                    <span className="text-center bg-white rounded-full p-1 w-6 h-6 block leading-none hover:bg-gray-200">&times;</span>
                </div>
                {(image && (
                    <>
                        <Image src={`image/${image.src}`} />
                    </>
                )) ||
                    null}
                {(position === 'tooltip' && <h4>{click_zone?.label}</h4>) || <h2>{click_zone?.label}</h2>}
                <p className="mb-2">
                    {(click_zone?.text && typeof click_zone.text === 'string' && click_zone?.text) ||
                        (click_zone?.text &&
                            typeof click_zone.text === 'object' &&
                            Object.keys(click_zone.text).map(
                                (key: string) =>
                                    key.substring(0, 1) !== '_' && (
                                        <span key={key} className="block">
                                            {key}: {click_zone.text[key]}
                                        </span>
                                    )
                            ))}
                </p>
                {(click_zone?.file_id && (
                    <>
                        {(() => {
                            const file = config.file_array?.find((f) => f.id === click_zone?.file_id)
                            return (
                                <Link href={FILE_URL + 'file/' + file?.src} target="_blank">
                                    {file?.label}
                                </Link>
                            )
                        })()}
                    </>
                )) ||
                    null}
            </div>
        </div>
    )
})

const DEFAULT_LINK_TEXT_COLOR = '#000000'
const DEFAULT_LINK_EMPHASIS_TEXT_COLOR = '#000000'
const Link = (props: any) => {
    const { color_array = [] } = useConfig()
    const [hovered, setHovered] = useState(false)
    const link_color = useMemo(() => color_array?.find((c) => c.id === 'navigation'), [])
    const link_emphasis_color = useMemo(() => color_array?.find((c) => c.id === 'navigation_emphasis'), [])

    const link_style = {
        color: link_color?.color ?? DEFAULT_LINK_TEXT_COLOR,
        fontWeight: 'bold',
    }
    const link_emphasis_style = {
        color: link_emphasis_color?.color ?? DEFAULT_LINK_EMPHASIS_TEXT_COLOR,
        fontWeight: 'bold',
        textDecoration: 'underline',
    }

    return <a {...props} style={hovered ? link_emphasis_style : link_style} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} />
}

export default DetailsPane
