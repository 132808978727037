import React from 'react'
import { TypeConfig } from '../types'
import { useConfig } from './hooks'
import ImageMenu from './ImageMenu'
import StaticPage from './StaticPage'

const Home = React.memo(function Home(props: any) {
    const { root = {} as any } = useConfig()
    const { image_menu_id, target_id } = root

    const model = target_id ? target_id.split('-')[0] : 'image_menu'
    const id = target_id ? target_id.split('-')[1] : image_menu_id

    return (
        <>
            <main className={'flex flex-col justify-center items-center w-screen h-screen'}>
                {(model === 'static_page' && <StaticPage id={id} />) || null}
                {(model === 'image_menu' && <ImageMenu id={id} />) || null}
            </main>
        </>
    )
})

export default Home
